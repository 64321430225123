@import "@/app/styles/global.scss";


//h1{
//  color: var(--crux-green);
//  font-size: 36px;
//  font-weight: 700;
//  //letter-spacing: 5px;
//}

//h2{
//  //color: var(--crux-green);
//  //font-size: 24px;
//  //font-weight: 700;
//  //letter-spacing: 4.8px;
//  //letter-spacing: 5px;
//  //font-weight: 100;
//}

//h2{
//  color: #777777;
//  font-size: 26px;
//  font-weight: 900;
//  letter-spacing: 2px;
//}

//h3{
//  //color: var(--crux-green);
//  //font-size: 20pt;
//  //letter-spacing: 4px;
//  //font-weight: 800;
//}

//
//h4{
//  //color: var(--crux-green);
//  //font-size: 12pt;
//}

.h5{
  color: var(--crux-gray);
  font-size: 10pt;
}